<template>
  <ApolloQuery ref="apolloQuery" v-bind="$attrs" @result="loaded($event)">
    <template #default="{ result: { error, data }, isLoading, query }">
      <slot v-if="error" name="error">
        <p>There was an error!</p>
        <p>{{ error }}</p>
      </slot>
      <slot v-else-if="isLoading" name="loading">
        <p>Loading...</p>
      </slot>
      <slot
        v-else
        name="default"
        :data="adaptor ? adaptor(data) : data"
        :loading="isLoading"
        :refresh="query.refetch"
      ></slot>
    </template>
  </ApolloQuery>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    adaptor: {
      type: Function,
      default: null,
    },
  },

  methods: {
    loaded({ data }) {
      this.$emit("update:data", data);
    },
    refresh() {
      this.$refs.apolloQuery.getApolloQuery().refetch();
    },
  },
};
</script>
